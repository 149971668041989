import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Llm_user_mastery_home from './true_productivity/Home';  // or whatever you named your home component
import Oneptwo from './true_productivity/Oneptwo';
import Onepone from './true_productivity/Onepone';
import Onepthree from './true_productivity/Onepthree'
import Onepfour from './true_productivity/Onepfour'
import Onepfive from './true_productivity/Onepfive'
import Onepsix from './true_productivity/Onepsix'
import Home from './Home'
import Python_llms_home from './python_llms/Home_python_llms';  // or whatever you named your home component

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/true_productivity_with_llms" element={<Llm_user_mastery_home />} />
      <Route path="/true_productivity_with_llms_1_2_introduction_to_llms" element={<Oneptwo />} />
      <Route path="/true_productivity_with_llms_1_1_welcome_and_introduction" element={<Onepone />} />
      <Route path="/true_productivity_with_llms_1_3_accessing_llms" element={<Onepthree />} />
      <Route path="/true_productivity_with_llms_1_4_llm_security_and_responsible_use" element={<Onepfour />} />
      <Route path="/true_productivity_with_llms_1_5_prompting" element={<Onepfive />} />
      <Route path="/true_productivity_with_llms_1_6_workshop" element={<Onepsix />} />
      <Route path="/python_llms" element={<Python_llms_home />} />
      {/* Add other routes as needed */}
    </Routes>
  );
};

export default App;