import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BookOpen, ChevronRight, Shield, Award, Layout, LogOut, Home as HomeIcon, User } from 'lucide-react';

const Home_python_llms = () => {
  const [courses, setCourses] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [pageConfig, setPageConfig] = useState({
    big_title: 'AIDATACERT.COM',
    little_title: 'Python with LLMs'
  });

  useEffect(() => {
    const loadExcelData = async () => {
      try {
        const auth = localStorage.getItem('isAuthenticated');
        const storedData = localStorage.getItem('courseData');
        
        if (auth === 'true' && storedData) {
          const data = JSON.parse(storedData);
          setCourses(data.modules || []);
          setPageConfig(data.pageConfig || {
            big_title: 'AIDATACERT.COM',
            little_title: 'Python with LLMs'
          });
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Failed to load course data');
      }
    };

    loadExcelData();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    try {
      console.log("Attempting login with:", { username });
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login-python`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password
        })
      });

      console.log("Response status:", response.status);
      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Login failed');
        return;
      }

      const formattedModules = (data.modules || []).map(module => {
        const moduleEntries = Object.entries(module);
        const moduleTitle = moduleEntries[0] ? moduleEntries[0][1] : '';
        
        return {
          title: moduleTitle,
          description: `Access module: ${moduleTitle}`,
          link: `/python_llms_${moduleTitle
            .toLowerCase()
            .replace(/\s+/g, '_')
            .replace(/\./g, '_')}`
        };
      });

      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('username', username);
      localStorage.setItem('courseData', JSON.stringify({
        modules: formattedModules,
        pageConfig: data.pageConfig
      }));

      window.location.reload();

    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const auth = localStorage.getItem('isAuthenticated');
    const storedData = localStorage.getItem('courseData');
    
    if (auth === 'true' && storedData) {
      try {
        const data = JSON.parse(storedData);
        setCourses(data.modules || []);
        setPageConfig({
          big_title: data.pageConfig?.big_title || 'AIDATACERT.COM',
          little_title: data.pageConfig?.little_title || 'Python with LLMs'
        });
        setIsAuthenticated(true);
        setUsername(localStorage.getItem('username') || '');
      } catch (error) {
        console.error('Error restoring session:', error);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('courseData');
        localStorage.removeItem('username');
      }
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
        <header className="bg-slate-950/90 backdrop-blur-md border-b border-slate-800/50">
          <div className="container mx-auto py-6 px-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-6">
                <img 
                  src="/logo.png" 
                  alt="AI DATA CERT Logo" 
                  className="h-14 w-auto"
                />
                <div className="border-l border-slate-700 pl-6">
                  <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-200">
                    {pageConfig.big_title}
                  </h1>
                  <p className="text-blue-300 text-sm font-medium">{pageConfig.little_title}</p>
                </div>
              </div>
              <Link 
                to="/"
                className="flex items-center space-x-2 px-4 py-2 rounded-xl bg-slate-800 hover:bg-slate-700 border border-slate-700/50 transition-all group"
              >
                <HomeIcon size={18} className="text-blue-400" />
                <span>Back to Courses</span>
              </Link>
            </div>
          </div>
        </header>

        <main className="flex-grow flex items-center justify-center px-4 py-20">
          <div className="w-full max-w-md">
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-2xl shadow-2xl border border-slate-700/30 p-10">
              <div className="text-center mb-8">
                <div className="bg-blue-500/10 w-16 h-16 rounded-xl flex items-center justify-center mx-auto mb-6">
                  <Shield className="w-8 h-8 text-blue-400" />
                </div>
                <h2 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
                  Secure Access Required
                </h2>
                <p className="text-slate-400">Please enter your login credentials</p>
              </div>
              
              <form onSubmit={handleLogin} className="space-y-6">
                <div className="space-y-4">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                      <User size={18} className="text-slate-400" />
                    </div>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Username"
                      className="w-full pl-10 pr-4 py-3 bg-slate-950/50 rounded-xl border border-slate-700 focus:border-blue-400 focus:ring-2 focus:ring-blue-400/20 transition-all placeholder-slate-500 text-lg"
                    />
                  </div>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                      <Shield size={18} className="text-slate-400" />
                    </div>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      className="w-full pl-10 pr-4 py-3 bg-slate-950/50 rounded-xl border border-slate-700 focus:border-blue-400 focus:ring-2 focus:ring-blue-400/20 transition-all placeholder-slate-500 text-lg"
                    />
                  </div>
                </div>
                
                {error && (
                  <div className="bg-red-500/10 border border-red-500/20 rounded-xl p-4">
                    <p className="text-red-400 text-sm">{error}</p>
                  </div>
                )}
                
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full py-3 px-4 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-500 hover:to-blue-400 rounded-xl text-white font-medium transition-all duration-300 flex items-center justify-center space-x-2 shadow-lg shadow-blue-500/20 group disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <div className="flex items-center space-x-2">
                      <svg 
                        className="animate-spin h-5 w-5 text-white" 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24"
                      >
                        <circle 
                          className="opacity-25" 
                          cx="12" 
                          cy="12" 
                          r="10" 
                          stroke="currentColor" 
                          strokeWidth="4"
                        />
                        <path 
                          className="opacity-75" 
                          fill="currentColor" 
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      <span>Signing In...</span>
                    </div>
                  ) : (
                    <>
                      <span>Access Platform</span>
                      <ChevronRight size={18} className="group-hover:translate-x-0.5 transition-transform" />
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </main>

        <footer className="bg-slate-950/90 backdrop-blur-md border-t border-slate-800/50 py-8">
          <div className="container mx-auto px-4 text-center text-slate-400">
            <p>&copy; {new Date().getFullYear()} {pageConfig.big_title}. All rights reserved.</p>
          </div>
        </footer>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
      <header className="bg-slate-950/90 backdrop-blur-md border-b border-slate-800/50 sticky top-0 z-50">
        <div className="container mx-auto py-4 px-4">
          <div className="flex flex-col md:flex-row items-center gap-4">
            <div className="flex items-center space-x-6">
              <img 
                src="/logo.png" 
                alt="AI DATA CERT Logo" 
                className="h-12 w-auto"
              />
              <div className="border-l border-slate-700 pl-6">
                <h1 className="text-xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-200">
                  {pageConfig.big_title}
                </h1>
              </div>
            </div>
            <div className="md:mx-auto">
              <h2 className="text-2xl md:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
                {pageConfig.little_title}
              </h2>
            </div>
            <div className="flex items-center space-x-4">
              <Link 
                to="/"
                className="flex items-center space-x-2 px-4 py-2 rounded-xl bg-slate-800 hover:bg-slate-700 border border-slate-700/50 transition-all group"
              >
                <HomeIcon size={18} className="text-blue-400" />
                <span>Back to Courses</span>
              </Link>
              <button
                onClick={() => {
                  localStorage.removeItem('isAuthenticated');
                  localStorage.removeItem('username');
                  setIsAuthenticated(false);
                }}
                className="flex items-center space-x-2 px-5 py-2.5 rounded-xl bg-slate-800 hover:bg-slate-700 border border-slate-700/50 transition-all group"
              >
                <LogOut size={18} className="group-hover:-translate-x-0.5 transition-transform" />
                <span>Sign Out</span>
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-16">
        <div className="flex items-center justify-between mb-12">
          <div>
            <h2 className="text-3xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
              Available Modules
            </h2>
            <p className="text-slate-400">Select a module to begin your learning journey</p>
          </div>
          <div className="flex items-center space-x-3 text-slate-400 bg-slate-800/50 px-4 py-2 rounded-xl border border-slate-700/50">
            <Layout size={20} />
            <span>Showing {courses.length} modules</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {courses.map((course, index) => (
            <div 
              key={index}
              className="group bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8 hover:border-blue-500/50 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <div className="flex items-start justify-between mb-6">
                <div className="p-3 bg-blue-500/10 rounded-xl group-hover:bg-blue-500/20 transition-colors">
                  <BookOpen className="h-6 w-6 text-blue-400" />
                </div>
                <Award className="h-5 w-5 text-slate-500 group-hover:text-blue-400 transition-colors" />
              </div>
              <h3 className="text-xl font-semibold mb-3 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400">
                {course.title}
              </h3>
              <p className="text-slate-400 text-sm mb-8">
                {course.description}
              </p>
              <Link 
                to={course.link} 
                className="flex items-center justify-between w-full px-4 py-3 bg-slate-950/50 hover:bg-gradient-to-r hover:from-blue-600 hover:to-blue-500 rounded-xl text-slate-300 hover:text-white transition-all group-hover:shadow-lg group-hover:shadow-blue-500/20"
              >
                <span className="font-medium">Begin Module</span>
                <ChevronRight size={18} className="group-hover:translate-x-0.5 transition-transform" />
              </Link>
            </div>
          ))}
        </div>
      </main>

      <footer className="bg-slate-950/90 backdrop-blur-md border-t border-slate-800/50 py-8">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-center space-x-4 text-slate-400">
            <img 
              src="/logo.png" 
              alt="AI DATA CERT Logo" 
              className="h-8 w-auto"
            />
            <span>|</span>
            <p>&copy; {new Date().getFullYear()} {pageConfig.big_title}. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home_python_llms;