import React, { useState, useEffect } from 'react';
import { ArrowLeft, Menu, FileText, User, Calendar, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Onepone = () => {
  const [activeSection, setActiveSection] = useState('welcome');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    // Immediate scroll to top without smooth behavior on initial load
    window.scrollTo(0, 0);
  }, []); 


  
  // Add a second useEffect to handle section changes
  useEffect(() => {
    // Smooth scroll to top when section changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeSection]);


  const handleSectionChange = (sectionId) => {
    // First change the section
    setActiveSection(sectionId);
    setIsMobileMenuOpen(false);
    
    // Then scroll to top with a slight delay to ensure the new content is rendered
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  };
  
  const sections = [
    { id: 'welcome', title: 'Welcome', icon: Calendar },
    { id: 'instructor', title: 'Your Instructor', icon: User },
  ];

  const contentSections = {
    welcome: {
      title: 'Welcome to the Course',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
              Welcome to True Productivity with LLMs
            </h3>
            <p className="text-slate-300">
              We're excited to have you join us on this learning journey. This course has been designed with the assumption of no prior knowledge of Large Language Models or Artificial Intelligence. We will introduce you to the theory of Large Language Models and then get you playing with them. In our final end-of-week workshop, we shall look at all of the current features the mainstream providers offer!
            </p>
          </div>
  
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-6">
            <h4 className="text-lg font-semibold text-blue-400 mb-4">What You'll Learn</h4>
            <ul className="space-y-3 text-slate-300">
              {[
                "What are Large Language Models (LLMs) and the main providers",
                "How to use LLMs safely",
                "How to prompt effectively",
                "You will be able to question documents, interpet images, generate web apps, automate data analysis, create dashboards, generate podcasts, summarise your inbox.🚀 "
              ].map((item, index) => (
                <li key={index} className="flex items-start space-x-2">
                  <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                    <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                  </div>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
  
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-6">
            <h4 className="text-lg font-semibold text-blue-400 mb-4">Course Structure</h4>
            <p className="text-slate-300 mb-4">
              There are 5 short modules (including this introductory module) for you to complete to prepare you for the workshop. It is essential you complete these modules in order to extract most value from the live interactive workshop. 
            </p>
            <ul className="space-y-3 text-slate-300">
              {[
                "1.2 Introduction to LLMs - An overview of how LLMs work, their history, use cases, and challenges",
                "1.3 Accessing LLMs - How to access LLMs",
                "1.4 LLM Policies and Security - Awareness of LLM security considerations and their link to policies",
                "1.5 Prompting - A guide to constructing effective messages for your LLM",
                "1.6 Workshop - Hands on demonstrations looking at current features and use cases of LLMs"
              ].map((item, index) => (
                <li key={index} className="flex items-start space-x-2">
                  <div className="bg-blue-500/20 rounded-full p-1 mt-1">
                    <div className="w-2 h-2 bg-blue-400 rounded-full"></div>
                  </div>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )
    },
  
    instructor: {
      title: 'Meet Your Instructor',
      content: (
        <div className="space-y-8">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="grid md:grid-cols-3 gap-8 items-start">
              <div className="md:col-span-1">
                <img
                  src="/pete_baksh_profile_pic.png"
                  alt="Dr. Peter Baksh"
                  className="w-full h-auto rounded-xl shadow-lg"
                />
              </div>

              <div className="md:col-span-2 space-y-6">
                <div>
                  <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-2">
                    Dr. Peter Baksh
                  </h3>
                  <p className="text-blue-400">AI Developer & Data Scientist</p>
                </div>

                <div className="prose prose-invert max-w-none">
                  <p className="text-slate-300">
                    Dr. Baksh is an AI developer and data scientist with extensive experience in machine learning, computer vision, and algorithm development. His background spans from implementing advanced neural networks (MLPs, CNNs, LSTMs, AEs) to developing custom AI solutions using generative models.
                  </p>
                  <p className="text-slate-300 mt-4">
                    With a PhD in Physics, vast experience in machine learning and educating FTSE-100 students in data science, he brings deep technical expertise in both theoretical and applied AI. His work includes developing novel AI solutions for investment companies, computer vision applications for defense technology, and implementing end-to-end machine learning pipelines.
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-slate-950/50 rounded-xl p-4 border border-slate-700/50">
                    <h4 className="text-sm font-semibold text-blue-400 mb-2">Technical Stack</h4>
                    <ul className="text-slate-300 text-sm space-y-1">
                      <li>• AWS, Azure, GCP</li>
                      <li>• Python, SQL, Docker</li>
                      <li>• Machine Learning & Deep Learning</li>
                    </ul>
                  </div>
                  <div className="bg-slate-950/50 rounded-xl p-4 border border-slate-700/50">
                    <h4 className="text-sm font-semibold text-blue-400 mb-2">Specialisations</h4>
                    <ul className="text-slate-300 text-sm space-y-1">
                      <li>• Generative AI Development</li>
                      <li>• Computer Vision Systems</li>
                      <li>• Education</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
      <div className="lg:hidden fixed top-4 right-4 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 bg-slate-800 rounded-xl border border-slate-700 hover:bg-slate-700 transition-colors"
        >
          <Menu size={24} />
        </button>
      </div>

      <div className={`fixed top-0 left-0 h-full bg-slate-900/95 backdrop-blur-md border-r border-slate-800/50 w-64 transform transition-transform duration-200 ease-in-out z-40 overflow-y-auto
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
      >
        <div className="p-6 space-y-6">
          <div className="flex justify-center mb-6">
            <img 
              src="/logo.png" 
              alt="AI DATA CERT Logo" 
              className="h-24 w-auto"
            />
          </div>

          <Link 
            to="/true_productivity_with_llms"
            className="inline-flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors group"
          >
            <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
            <span>Back to Modules</span>
          </Link>

          <nav className="space-y-1">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => setActiveSection(section.id)}
                className={`w-full text-left px-4 py-3 rounded-xl transition-all flex items-center space-x-3
                  ${activeSection === section.id 
                    ? 'bg-blue-500/20 text-blue-400 border border-blue-500/50 shadow-lg shadow-blue-500/20' 
                    : 'hover:bg-slate-800/50 text-slate-300'}`}
              >
                <section.icon size={18} />
                <span>{section.title}</span>
              </button>
            ))}

          {/* Begin Learning Button */}
          <Link 
            to="/true_productivity_with_llms_1_2_introduction_to_llms"
            className="w-full text-left px-4 py-3 rounded-xl transition-all flex items-center space-x-3 bg-green-500/20 border border-green-500/50 text-green-400"
          >
            <ArrowRight size={18} className="group-hover:translate-x-1 transition-transform" />
            <span>Start Course</span>
          </Link>
          </nav>
        </div>
      </div>

      <div className="lg:pl-64">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto">
            <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl shadow-xl border border-slate-700/30 p-8 mb-8">
              <div className="border-b border-slate-700 pb-6 mb-6">
                <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                  Course Overview
                </h1>
                <h2 className="text-xl text-blue-400">AI DATA CERT</h2>
              </div>

              <div className="space-y-6">
                <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                  {contentSections[activeSection]?.title}
                </h3>
                {contentSections[activeSection]?.content}
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onepone;