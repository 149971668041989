import React, { useState, useEffect} from 'react';
import { ArrowLeft, Menu, MessageSquare, Wand2, Sparkles, AlertCircle, CheckCircle, ArrowRight, GraduationCap, Brain, RefreshCw, Copy, Search, BarChart2, Upload } from 'lucide-react';
import { Link } from 'react-router-dom';
import Quiz from '../Quiz';
import FlipCard from '../FlipCard';

const Onepfive = () => {
  const [activeSection, setActiveSection] = useState('intro');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isQuizComplete, setIsQuizComplete] = useState(false);  // Add this state

  useEffect(() => {
    // Immediate scroll to top without smooth behavior on initial load
    window.scrollTo(0, 0);
  }, []); 


  
  // Add a second useEffect to handle section changes
  useEffect(() => {
    // Smooth scroll to top when section changes
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [activeSection]);


  const handleSectionChange = (sectionId) => {
    // First change the section
    setActiveSection(sectionId);
    setIsMobileMenuOpen(false);
    
    // Then scroll to top with a slight delay to ensure the new content is rendered
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  };

  const sections = [
    { id: 'intro', title: 'Introduction', icon: MessageSquare },
    { id: 'core', title: 'Core Techniques', icon: Wand2 },
    { id: 'advanced', title: 'Advanced Strategies', icon: Sparkles },
    { id: 'hallucinations', title: 'Hallucinations', icon: AlertCircle },
    { id: 'summary', title: 'Summary', icon: CheckCircle },
    { id: 'quiz', title: 'Knowledge Check', icon: GraduationCap },   // Academic cap for quiz/learning
  ];

  const contentSections = {
    intro: {
      title: 'Introduction to Prompting',
      content: (
        <div className="space-y-8">
          {/* Hero Section */}
          <div className="relative bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-12 border border-slate-700/30 overflow-hidden">
            <div className="absolute top-0 right-0 w-1/2 h-full opacity-10">
              <div className="absolute transform rotate-45 translate-x-1/2 -translate-y-1/2 bg-blue-500/20 w-96 h-96 blur-3xl"></div>
            </div>
            
            <div className="relative z-10">
              <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
                The Art of Prompting
              </h3>
              <p className="text-slate-300 text-lg max-w-3xl">
                Learning to communicate effectively with Large Language Models (LLMs) is like learning a new language. Just as we adjust our communication style when talking to different people, crafting the right prompt for an LLM can dramatically improve its responses.
              </p>
            </div>
          </div>

          {/* What is Prompting */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">What is Prompting?</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
              <div className="space-y-4">
                <p className="text-slate-300">
                  Prompting is how we communicate our requests to LLMs. It's the art of crafting clear, specific instructions that help the AI understand exactly what we want it to do.
                </p>
                <p className="text-slate-300">
                  Think of it like giving directions to someone in a foreign city - the clearer and more specific you are, the more likely you are to get where you want to go.
                </p>
              </div>
              
              <div className="bg-slate-800/50 rounded-xl p-6">
                <img 
                  src="https://images.pexels.com/photos/1181208/pexels-photo-1181208.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="Prompting Visualization"
                  className="w-full rounded-lg object-cover mb-4"
                />
                <p className="text-sm text-slate-400 text-center">
                  
                </p>
              </div>
            </div>
          </div>

          {/* Why Prompting Matters */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Why Good Prompting Matters</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Better Results</h5>
                <p className="text-slate-300">
                  Well-crafted prompts lead to more accurate, relevant, and useful responses from the AI.
                </p>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Time Saving</h5>
                <p className="text-slate-300">
                  Clear prompts reduce the need for multiple attempts and clarifications.
                </p>
              </div>
              
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Reduced Errors</h5>
                <p className="text-slate-300">
                  Good prompting helps minimise AI hallucinations and misunderstandings.
                </p>
              </div>
            </div>
          </div>


          {/* What We'll Learn */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
            <h4 className="text-lg font-semibold text-blue-400 mb-4">What You'll Learn</h4>
            <div className="space-y-3">
              <p className="text-slate-300">In this module, we'll cover:</p>
              <ul className="space-y-2 text-slate-300">
                <li className="flex items-start space-x-2">
                  <div className="mt-1.5">
                    <div className="w-1.5 h-1.5 bg-blue-400 rounded-full"></div>
                  </div>
                  <span>The CO-STAR framework for structured prompting</span>
                </li>
                <li className="flex items-start space-x-2">
                  <div className="mt-1.5">
                    <div className="w-1.5 h-1.5 bg-blue-400 rounded-full"></div>
                  </div>
                  <span>Advanced strategies for complex tasks</span>
                </li>
                <li className="flex items-start space-x-2">
                  <div className="mt-1.5">
                    <div className="w-1.5 h-1.5 bg-blue-400 rounded-full"></div>
                  </div>
                  <span>How to reduce hallucinations and errors</span>
                </li>
                <li className="flex items-start space-x-2">
                  <div className="mt-1.5">
                    <div className="w-1.5 h-1.5 bg-blue-400 rounded-full"></div>
                  </div>
                  <span>Practical exercises to build your prompting skills</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    },
    
    core: {
      title: 'Core Prompting Techniques: The CO-STAR Framework',
      content: (
        <div className="space-y-8">
          {/* Introduction to CO-STAR */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              The CO-STAR Framework
            </h3>
            <p className="text-slate-300 text-lg">
              CO-STAR is a structured approach to crafting effective prompts. Think of it as a recipe for clear communication with AI - each letter represents an essential ingredient for better results.
            </p>
          </div>

          {/* Framework Breakdown */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="bg-blue-500/20 rounded-full p-2">
                    <span className="text-blue-400 font-bold text-lg">C</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Context</h5>
                </div>
                <p className="text-slate-300 mb-4">
                  Background information that helps the AI understand the situation.
                </p>
                <div className="bg-slate-900/50 rounded-lg p-3">
                  <p className="text-sm text-slate-400">
                    Example: "As a financial advisor working with first-time investors..."
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="bg-blue-500/20 rounded-full p-2">
                    <span className="text-blue-400 font-bold text-lg">O</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Objective</h5>
                </div>
                <p className="text-slate-300 mb-4">
                  Clear statement of what you want to achieve.
                </p>
                <div className="bg-slate-900/50 rounded-lg p-3">
                  <p className="text-sm text-slate-400">
                    Example: "Create a beginner-friendly guide explaining mutual funds..."
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="bg-blue-500/20 rounded-full p-2">
                    <span className="text-blue-400 font-bold text-lg">S</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Style</h5>
                </div>
                <p className="text-slate-300 mb-4">
                  The writing style you want the AI to use.
                </p>
                <div className="bg-slate-900/50 rounded-lg p-3">
                  <p className="text-sm text-slate-400">
                    Example: "Use simple language and avoid technical jargon..."
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="bg-blue-500/20 rounded-full p-2">
                    <span className="text-blue-400 font-bold text-lg">T</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Tone</h5>
                </div>
                <p className="text-slate-300 mb-4">
                  The emotional quality of the response.
                </p>
                <div className="bg-slate-900/50 rounded-lg p-3">
                  <p className="text-sm text-slate-400">
                    Example: "Keep the tone friendly and encouraging..."
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="bg-blue-500/20 rounded-full p-2">
                    <span className="text-blue-400 font-bold text-lg">A</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Audience</h5>
                </div>
                <p className="text-slate-300 mb-4">
                  Who the response is for.
                </p>
                <div className="bg-slate-900/50 rounded-lg p-3">
                  <p className="text-sm text-slate-400">
                    Example: "This is for young professionals in their 20s..."
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-2 mb-4">
                  <div className="bg-blue-500/20 rounded-full p-2">
                    <span className="text-blue-400 font-bold text-lg">R</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Response Format</h5>
                </div>
                <p className="text-slate-300 mb-4">
                  How you want the information structured.
                </p>
                <div className="bg-slate-900/50 rounded-lg p-3">
                  <p className="text-sm text-slate-400">
                    Example: "Present this as a bulleted list with examples..."
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Complete Example */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">CO-STAR in Action</h4>
            
            <div className="space-y-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Before CO-STAR</h5>
                <div className="bg-red-500/10 rounded-lg p-4 border border-red-500/20">
                  <p className="text-slate-300">
                    "Write about investing for beginners"
                  </p>
                </div>
                <p className="text-sm text-slate-400 mt-2">
                  This vague prompt could lead to generic or overwhelming information.
                </p>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">After CO-STAR</h5>
                <div className="bg-green-500/10 rounded-lg p-4 border border-green-500/20">
                  <p className="text-slate-300">
                    "Context: As a financial advisor helping young professionals start their investment journey.<br/><br/>
                    Objective: Create an introduction to investing that focuses on the three most important steps for beginners.<br/><br/>
                    Style: Use clear, straightforward language with real-world examples.<br/><br/>
                    Tone: Encouraging and supportive, acknowledging common fears about investing.<br/><br/>
                    Audience: First-time investors in their mid-20s with stable income but limited financial knowledge.<br/><br/>
                    Response Format: Provide a step-by-step guide with examples and action items at the end of each section."
                  </p>
                </div>
                <p className="text-sm text-slate-400 mt-2">
                  This structured prompt is more likely to generate targeted, useful information.
                </p>
              </div>
            </div>
          </div>

          {/* Interactive Task */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Task 1: Improving Your Prompts</h4>
            
            <div className="space-y-6">
              <p className="text-slate-300">
                Let's practice using the CO-STAR framework to improve a basic prompt. We'll work with this scenario:
              </p>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Your Challenge</h5>
                <p className="text-slate-300">
                  Transform this basic prompt into a comprehensive CO-STAR prompt:
                </p>
                <div className="bg-slate-900/50 rounded-lg p-4 mt-4">
                  <p className="text-slate-300">
                    "How do I create a social media strategy?"
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Steps to Complete</h5>
                <ol className="space-y-4 text-slate-300">
                  <li>1. Open your preferred LLM (ChatGPT, Claude, etc.)</li>
                  <li>2. Try the basic prompt first and note the response</li>
                  <li>3. Create a new prompt using each element of CO-STAR:
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>• Add relevant context about the business/situation</li>
                      <li>• Clarify your specific objectives</li>
                      <li>• Specify your preferred style</li>
                      <li>• Define the tone you want</li>
                      <li>• Identify your target audience</li>
                      <li>• Request a specific response format</li>
                    </ul>
                  </li>
                  <li>4. Try your new prompt and compare the responses</li>
                </ol>
              </div>

              <div className="bg-slate-900/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Example Solution Structure</h5>
                <p className="text-slate-300">
                  Context: "As a small local bakery looking to expand our online presence..."<br/>
                  Objective: "Create a 3-month social media strategy focused on increasing local awareness..."<br/>
                  Style: "Use practical, actionable language..."<br/>
                  Tone: "Professional but warm..."<br/>
                  Audience: "For implementation by a small team with basic social media knowledge..."<br/>
                  Response: "Provide a weekly breakdown with specific post types, timing, and content themes..."
                </p>
              </div>
            </div>
          </div>

          {/* Success Indicators */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-6">
            <h4 className="text-lg font-semibold text-blue-400 mb-4">How to Know It's Working</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-slate-800/50 rounded-lg p-4">
                <h5 className="font-semibold text-blue-300 mb-2">Better Response Signs</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• More specific, targeted information</li>
                  <li>• Better organised content</li>
                  <li>• Appropriate level of detail</li>
                  <li>• Matches requested format</li>
                </ul>
              </div>
              <div className="bg-slate-800/50 rounded-lg p-4">
                <h5 className="font-semibold text-blue-300 mb-2">Common Improvements</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• More practical examples</li>
                  <li>• Clearer action items</li>
                  <li>• Better aligned with needs</li>
                  <li>• More consistent tone</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    },

advanced: {
      title: 'Advanced Prompting Strategies',
      content: (
        <div className="space-y-8">
          {/* Introduction */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Beyond the Basics: Advanced Prompting
            </h3>
            <p className="text-slate-300 text-lg">
              While CO-STAR provides a solid foundation, advanced strategies can help you handle more complex tasks and get even better results from your LLM interactions.
            </p>
          </div>

          {/* Key Advanced Strategies Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="h-[450px]">
            <FlipCard 
              frontTitle="Chain of Thought Prompting"
              frontIcon={<Brain className="w-8 h-8 text-blue-400" />}
              frontContent={
                <div className="space-y-4">
                  <div className="h-48 mb-4 rounded-xl overflow-hidden">
                    <img 
                      src="https://images.pexels.com/photos/3183183/pexels-photo-3183183.jpeg" 
                      alt="Step by step problem solving"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-slate-300">
                    A technique where you ask the LLM to break down complex problems into steps and show its reasoning process, leading to more accurate and transparent solutions.
                  </p>
                  <div className="space-y-4 flex justify-center">
                  <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  </div>
                  </div>
                </div>
              }
              backTitle="Chain of Thought Example"
              backContent={
               <div className="space-y-4">
                 <div className="bg-slate-800/50 p-4 rounded-lg mb-6">
                   <p className="font-medium text-blue-300 mb-2">What is Chain of Thought?</p>
                   <p className="text-slate-300">
                     Chain of Thought is a technique where you ask the AI to break down complex problems into clear, logical steps - like showing your work in a math problem. This helps ensure accuracy and makes the reasoning process clear.
                   </p>
                 </div>
              
                 <p className="text-slate-300">Let's see it in action with a business example:</p>
                 
                 <div className="bg-slate-800/50 p-4 rounded-lg">
                   <p className="font-medium mb-4 text-blue-400">Business Case Analysis</p>
                   
                   <div className="border-l-2 border-purple-500/50 pl-4 mb-6">
                     <p className="text-slate-300">
                       Instead of just asking:<br/>
                       <span className="text-red-400 italic">"What's the profit margin of our product?"</span><br/>
                       <br/>
                       We use Chain of Thought:<br/>
                       <span className="text-green-400 italic">"Let's calculate the profit margin step by step, considering all costs and market factors."</span>
                     </p>
                   </div>
              
                   <div className="space-y-6">
                     <div className="border-l-2 border-blue-500/50 pl-4">
                       <p className="font-medium text-blue-300">Step 1: Calculate Total Costs</p>
                       <ul className="text-slate-300 space-y-2 mt-2">
                         <li>• Raw Materials: £35 per unit</li>
                         <li>• Labour Hours: £12 per unit</li>
                         <li>• Overhead Costs: £8 per unit</li>
                         <li>→ Total Cost = £55 per unit</li>
                       </ul>
                     </div>
              
                     <div className="border-l-2 border-blue-500/50 pl-4">
                       <p className="font-medium text-blue-300">Step 2: Market Analysis</p>
                       <ul className="text-slate-300 space-y-2 mt-2">
                         <li>• Competitor pricing range: £85-£95</li>
                         <li>• Target market positioning: Premium</li>
                         <li>→ Optimal selling price = £90 per unit</li>
                       </ul>
                     </div>
              
                     <div className="border-l-2 border-blue-500/50 pl-4">
                       <p className="font-medium text-blue-300">Step 3: Calculate Profit</p>
                       <ul className="text-slate-300 space-y-2 mt-2">
                         <li>• Revenue: £90 (selling price)</li>
                         <li>• Cost: £55 (total cost)</li>
                         <li>→ Profit = £90 - £55 = £35 per unit</li>
                       </ul>
                     </div>
              
                     <div className="border-l-2 border-blue-500/50 pl-4">
                       <p className="font-medium text-blue-300">Step 4: Calculate Margins</p>
                       <ul className="text-slate-300 space-y-2 mt-2">
                         <li>• Profit Margin = (Profit ÷ Revenue) × 100</li>
                         <li>• (£35 ÷ £90) × 100 = 38.9%</li>
                       </ul>
                     </div>
              
                     <div className="border-l-2 border-green-500/50 pl-4 bg-green-900/20 p-3 rounded">
                       <p className="font-medium text-green-300">Why This Works Better:</p>
                       <ul className="text-slate-300 space-y-2 mt-2">
                         <li>• Makes complex calculations transparent</li>
                         <li>• Catches potential errors early</li>
                         <li>• Shows all factors considered</li>
                         <li>• Provides a clear audit trail</li>
                         <li>• Makes assumptions explicit</li>
                       </ul>
                     </div>
                   </div>
                 </div>
               </div>
              }
            />
</div>
            <FlipCard 
              frontTitle="Role Assignment"
              frontIcon={<Sparkles className="w-8 h-8 text-purple-400" />}
              frontContent={
                <div className="space-y-4">
                  <div className="h-48 mb-4 rounded-xl overflow-hidden">
                    <img 
                      src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg" 
                      alt="Professional roles"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-slate-300">
                    A strategy where you assign the LLM a specific professional role or perspective to frame its responses and provide more focused, relevant expertise.
                  </p>
                  <div className="space-y-4 flex justify-center">
                  <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  </div>
                  </div>
                </div>
              }
              backTitle="Role Assignment Example"
              backContent={
                <div className="space-y-4">
                  <p className="text-slate-300">Professional Role Examples:</p>
                  <div className="bg-slate-800/50 p-4 rounded-lg">
                    <div className="space-y-6">
                      <div className="border-l-2 border-blue-500/50 pl-4">
                        <p className="font-medium text-blue-300">Data Scientist Role:</p>
                        <div className="mt-2 space-y-2">
                          <p className="text-slate-300 italic">"Act as a senior data scientist with 10 years of experience in machine learning. Explain complex algorithms to non-technical stakeholders, focusing on practical business applications and ROI."</p>
                          <div className="bg-slate-900/30 p-2 rounded text-green-300 text-sm mt-1">
                            Best for: Technical explanations, data analysis, methodology discussions
                          </div>
                        </div>
                      </div>
              
                      <div className="border-l-2 border-purple-500/50 pl-4">
                        <p className="font-medium text-purple-300">Financial Advisor Role:</p>
                        <div className="mt-2 space-y-2">
                          <p className="text-slate-300 italic">"Take on the role of a chartered financial advisor with expertise in UK pensions and investments. Provide balanced advice considering risk tolerance and market conditions."</p>
                          <div className="bg-slate-900/30 p-2 rounded text-green-300 text-sm mt-1">
                            Best for: Investment guidance, retirement planning, risk assessment
                          </div>
                        </div>
                      </div>
              
                      <div className="border-l-2 border-yellow-500/50 pl-4">
                        <p className="font-medium text-yellow-300">Educational Consultant Role:</p>
                        <div className="mt-2 space-y-2">
                          <p className="text-slate-300 italic">"Assume the role of an experienced educational consultant specialising in UK secondary education. Provide guidance on curriculum development and student engagement strategies."</p>
                          <div className="bg-slate-900/30 p-2 rounded text-green-300 text-sm mt-1">
                            Best for: Lesson planning, assessment strategies, learning objectives
                          </div>
                        </div>
                      </div>
              
                      <div className="border-l-2 border-green-500/50 pl-4 bg-green-900/20 p-3 rounded">
                        <p className="font-medium text-green-300">Role Components:</p>
                        <ul className="list-disc list-inside text-slate-300 space-y-1 mt-2">
                          <li>Specific expertise level</li>
                          <li>Clear specialisation area</li>
                          <li>Target audience awareness</li>
                          <li>Communication style guidance</li>
                          <li>Practical application focus</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <FlipCard 
              frontTitle="Iterative Refinement"
              frontIcon={<RefreshCw className="w-8 h-8 text-green-400" />}
              frontContent={
                <div className="space-y-4">
                  <div className="h-48 mb-4 rounded-xl overflow-hidden">
                    <img 
                      src="https://images.pexels.com/photos/1181671/pexels-photo-1181671.jpeg" 
                      alt="Iterative process"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-slate-300">
                    A method of using follow-up prompts to progressively improve and refine the LLM's responses, getting closer to your desired outcome with each iteration.
                  </p>
                  <div className="space-y-4 flex justify-center">
                  <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  </div>
                  </div>
                </div>
              }
              backTitle="Refinement Process Example"
              backContent={
                <div className="space-y-4">
                  <p className="text-slate-300">SPAG Improvement Sequence:</p>
                  <div className="bg-slate-800/50 p-4 rounded-lg">
                    <p className="font-medium mb-4 text-blue-400">Initial Text:</p>
                    <div className="space-y-6">
                      <div className="border-l-2 border-red-500/50 pl-4 bg-red-900/10 p-3 rounded">
                        <p className="italic text-slate-300">"The boy was happy because he got good marks in his test and his mum was proud of him and gave him a reward."</p>
                      </div>
              
                      <div className="space-y-4">
                        <div className="border-l-2 border-blue-500/50 pl-4">
                          <p className="font-medium text-blue-300">Step 1: Basic Corrections</p>
                          <p className="text-slate-300">"Please improve the punctuation and conjunctions"</p>
                          <p className="italic text-green-300 mt-2">→ "The boy was happy because he got good marks in his test. His mum was proud of him; therefore, she gave him a reward."</p>
                        </div>
              
                        <div className="border-l-2 border-blue-500/50 pl-4">
                          <p className="font-medium text-blue-300">Step 2: Vocabulary Enhancement</p>
                          <p className="text-slate-300">"Use more sophisticated vocabulary and emotions"</p>
                          <p className="italic text-green-300 mt-2">→ "The delighted pupil received excellent marks in his assessment. His mother was overjoyed with his achievement; consequently, she presented him with a special reward."</p>
                        </div>
              
                        <div className="border-l-2 border-blue-500/50 pl-4">
                          <p className="font-medium text-blue-300">Step 3: Literary Devices</p>
                          <p className="text-slate-300">"Add descriptive elements and figurative language"</p>
                          <p className="italic text-green-300 mt-2">→ "Beaming like the morning sun, the dedicated pupil received excellent marks in his challenging assessment. His mother's eyes sparkled with pride at his achievement; consequently, she presented him with a well-deserved reward for his diligent efforts."</p>
                        </div>
                      </div>
              
                      <div className="border-l-2 border-green-500/50 pl-4 bg-green-900/20 p-3 rounded">
                        <p className="font-medium text-green-300">Key Improvements:</p>
                        <ul className="list-disc list-inside text-slate-300 space-y-1 mt-2">
                          <li>Enhanced punctuation usage</li>
                          <li>Advanced vocabulary choices</li>
                          <li>Varied sentence structures</li>
                          <li>Added descriptive elements</li>
                          <li>Improved cohesion</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <FlipCard 
              frontTitle="Few-Shot Examples"
              frontIcon={<Copy className="w-8 h-8 text-yellow-400" />}
              frontContent={
                <div className="space-y-4">
                  <div className="h-48 mb-4 rounded-xl overflow-hidden">
                    <img 
                      src="https://images.pexels.com/photos/1181677/pexels-photo-1181677.jpeg" 
                      alt="Example patterns"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <p className="text-slate-300">
                    A technique where you provide example input-output pairs of examples  to demonstrate the pattern you want the LLM to follow in its responses.
                  </p>
                                    <div className="space-y-4 flex justify-center">
                  <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
                  <span>click to explore</span>
                  </div>
                  </div>
                </div>
              }
              backTitle="Few-Shot Example Pattern"
              backContent={
                <div className="space-y-4">
                  <p className="text-slate-300">Email Response Example Pattern:</p>
                  <div className="bg-slate-800/50 p-4 rounded-lg">
                    <p className="font-medium mb-4 text-blue-400">Customer Service Template:</p>
                    <div className="space-y-6 text-slate-300">
                      <div className="border-l-2 border-blue-500/50 pl-4">
                        <p className="font-medium text-blue-300">Input:</p>
                        <p className="italic">"Late delivery complaint"</p>
                        <p className="font-medium text-blue-300 mt-2">Output:</p>
                        <p>"I understand your frustration with the delayed delivery. I've checked your order status and will expedite it immediately. We'll provide a 10% refund for the inconvenience."</p>
                      </div>
              
                      <div className="border-l-2 border-blue-500/50 pl-4">
                        <p className="font-medium text-blue-300">Input:</p>
                        <p className="italic">"Product quality issue"</p>
                        <p className="font-medium text-blue-300 mt-2">Output:</p>
                        <p>"I apologise for the quality concerns with your product. Please send photos of the issue, and we'll arrange an immediate replacement or refund based on your preference."</p>
                      </div>
              
                      <div className="border-l-2 border-green-500/50 pl-4 bg-green-900/20 p-3 rounded">
                        <p className="font-medium text-green-300">Now your turn:</p>
                        <p className="italic">"Input: Billing error complaint"</p>
                        <p className="text-green-300 mt-2">The model will follow the same empathetic, solution-focused pattern</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
          <br></br>
          <br></br>
     
      
    

          {/* Visual Examples */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <div className="flex items-center justify-between mb-6">
              <h4 className="text-xl font-semibold text-blue-400">Advanced Techniques in Action</h4>
              <button 
                onClick={() => {
                  // Here you would implement the actual download logic
                  const link = document.createElement('a');
                  link.href = '/advanced-prompting.png'; // Replace with actual image path
                  link.download = 'advanced-prompting-techniques.png';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
                className="flex items-center space-x-2 px-4 py-2 bg-blue-500/10 hover:bg-blue-500/20 
                  border border-blue-500/30 rounded-lg transition-all duration-200"
              >
                <svg 
                  className="w-5 h-5 text-blue-400" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                <span className="text-blue-300">Download Image</span>
              </button>
            </div>
            
            <div className="grid grid-cols-1 gap-6">
              <div className="bg-slate-800/50 rounded-xl p-6">
                <div className="relative group">
                  <img 
                    src="https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/advanced-prompting.png"
                    alt="Advanced Prompting Techniques Visualization"
                    className="w-full rounded-lg object-cover mb-4"
                  />
                  {/* Optional hover overlay */}
                  <div className="absolute inset-0 bg-blue-500/0 group-hover:bg-blue-500/10 
                    rounded-lg transition-all duration-200" />
                </div>
                <p className="text-sm text-slate-400 text-center">
                  Visual guide to advanced prompting strategies and their applications
                </p>
              </div>
            </div>
          </div>


          
          {/* Practical Applications */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">When to Use Advanced Techniques</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Complex Problems</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Multi-step analysis</li>
                  <li>• Technical explanations</li>
                  <li>• Problem-solving tasks</li>
                </ul>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Creative Tasks</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Content generation</li>
                  <li>• Brainstorming</li>
                  <li>• Story development</li>
                </ul>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Specialised Content</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Domain-specific writing</li>
                  <li>• Technical documentation</li>
                  <li>• Educational materials</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Interactive Task */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Task 2: Advanced Prompting Challenge</h4>
            
            <div className="space-y-6">
              <p className="text-slate-300">
                Let's practice combining multiple advanced techniques to create a comprehensive analysis.
              </p>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Your Challenge</h5>
                <p className="text-slate-300 mb-4">
                  Create a detailed analysis of a business problem using multiple advanced prompting techniques.
                </p>

                <div className="space-y-4">
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300 font-semibold">Scenario:</p>
                    <p className="text-slate-300">
                      "A small retail business is experiencing declining foot traffic and needs to develop a strategy to attract younger customers while retaining their existing older customer base."
                    </p>
                  </div>

                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300 font-semibold">Task Steps:</p>
                    <ol className="list-decimal list-inside space-y-2 text-slate-300">
                      <li>Start with a role assignment for the LLM</li>
                      <li>Use chain-of-thought prompting for analysis</li>
                      <li>Provide few-shot examples of successful strategies</li>
                      <li>Use iterative refinement to improve the plan</li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Example Approach</h5>
                <div className="space-y-4">
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">
                      1. Initial Role Assignment:<br/>
                      "Act as an experienced retail business consultant with expertise in multi-generational marketing strategies..."
                    </p>
                  </div>
                  
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">
                      2. Chain of Thought:<br/>
                      "Let's analyse this problem step by step:
                      - First, assess current customer demographics
                      - Next, identify key challenges
                      - Then, explore potential solutions..."
                    </p>
                  </div>

                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">
                      3. Few-Shot Examples:<br/>
                      "Here are two similar cases and their solutions:
                      Case 1: [Example]
                      Solution: [Outcome]..."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Best Practices */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-6">
            <h4 className="text-lg font-semibold text-blue-400 mb-4">Advanced Prompting Best Practices</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-slate-800/50 rounded-lg p-4">
                <h5 className="font-semibold text-blue-300 mb-2">Do's</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Combine multiple techniques when appropriate</li>
                  <li>• Start simple and add complexity gradually</li>
                  <li>• Use clear examples to guide responses</li>
                  <li>• Review and refine outputs iteratively</li>
                </ul>
              </div>
              <div className="bg-slate-800/50 rounded-lg p-4">
                <h5 className="font-semibold text-blue-300 mb-2">Don'ts</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>• Overcomplicate prompts unnecessarily</li>
                  <li>• Mix too many techniques at once</li>
                  <li>• Forget to specify output format</li>
                  <li>• Ignore context and audience needs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    },

    hallucinations: {
      title: 'Prompting Strategies to Prevent Hallucinations',
      content: (
        <div className="space-y-8">
          {/* Brief Recap */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <div className="flex items-start space-x-4">
              <div className="bg-blue-500/10 p-3 rounded-xl">
                <AlertCircle className="w-8 h-8 text-blue-400" />
              </div>
              <div>
                <h3 className="text-1xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                  Crafting Prompts for Accuracy
                </h3>
                <p className="text-slate-300 text-md">
                  Having explored what hallucinations are in the previous chapter, let's focus on practical strategies to minimise their occurrence through better prompt design.
                </p>
              </div>
            </div>
          </div>

{/* Core Prevention Strategies */}
<div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
  <h4 className="text-xl font-semibold text-blue-400 mb-6">Key Prevention Strategies</h4>
  
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    <FlipCard 
      frontTitle="Web Search Integration"
      frontIcon={<Search className="w-8 h-8 text-blue-400" />}
      frontContent={
        <div className="space-y-4">
          <div className="h-48 mb-4 rounded-xl overflow-hidden">
            <img 
              src="https://images.pexels.com/photos/196655/pexels-photo-196655.jpeg" 
              alt="Search Engine"
              className="w-full h-full object-cover"
            />
          </div>
          <p className="text-slate-300">
            Enhance accuracy using an LLM with web search.
          </p>
          <div className="mt-4 flex items-center justify-center">
            <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
              <span>click to explore</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      }
      backTitle="Web Search Strategy"
      backContent={
        <div className="space-y-4">
          <div className="bg-slate-800/50 p-4 rounded-lg">
            <p className="font-medium text-blue-300 mb-3">How It Works:</p>
            <div className="space-y-3 text-slate-300">
              <p>Some LLMs have the capability to search the internet in real-time, providing current and verified information for your queries.</p>
              
              <div className="border-l-2 border-blue-500/50 pl-4 mt-4">
                <p className="font-medium text-blue-300">Key Benefits:</p>
                <ul className="list-disc list-inside space-y-1 mt-2">
                  <li>Access to current information</li>
                  <li>Fact verification capability</li>
                  <li>Source citation available</li>
                </ul>
              </div>

              <div className="bg-slate-900/50 rounded-lg p-3 mt-4">
                <p className="font-medium text-blue-300">Example Prompt:</p>
                <p className="text-sm text-slate-300 mt-2">
                  "Using your web search capability, please provide the latest market share statistics for electric vehicles in the UK, citing your sources."
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    />

    <FlipCard 
      frontTitle="Confidence Levels"
      frontIcon={<BarChart2 className="w-8 h-8 text-purple-400" />}
      frontContent={
        <div className="space-y-4">
          <div className="h-48 mb-4 rounded-xl overflow-hidden">
            <img 
              src="https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg" 
              alt="Confidence levels"
              className="w-full h-full object-cover"
            />
          </div>
          <p className="text-slate-300">
          Request response confidence from the LLM.
          </p>
          <div className="mt-4 flex items-center justify-center">
            <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
              <span>click to explore</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      }
      backTitle="Understanding Confidence Levels"
      backContent={
        <div className="space-y-4">
          <div className="bg-slate-800/50 p-4 rounded-lg">
            <p className="font-medium text-blue-300 mb-3">Implementation Guide:</p>
            <div className="space-y-3 text-slate-300">
              <div className="border-l-2 border-purple-500/50 pl-4">
                <p className="font-medium text-purple-300">Rating Scale:</p>
                <ul className="space-y-1 mt-2">
                  <li>5 - Absolutely certain</li>
                  <li>4 - Highly confident</li>
                  <li>3 - Moderately confident</li>
                  <li>2 - Somewhat uncertain</li>
                  <li>1 - Very uncertain</li>
                </ul>
              </div>

              <div className="bg-slate-900/50 rounded-lg p-3 mt-4">
                <p className="font-medium text-blue-300">Example Prompt:</p>
                <p className="text-sm text-slate-300 mt-2">
                  "Please provide an analysis of upcoming technology trends, rating your confidence level (1-5) for each prediction and explaining your reasoning."
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    />

    <FlipCard 
      frontTitle="Document Upload"
      frontIcon={<Upload className="w-8 h-8 text-green-400" />}
      frontContent={
        <div className="space-y-4">
          <div className="h-48 mb-4 rounded-xl overflow-hidden">
            <img 
              src="https://images.pexels.com/photos/147413/twitter-facebook-together-exchange-of-information-147413.jpeg" 
              alt="Document upload"
              className="w-full h-full object-cover"
            />
          </div>
          <p className="text-slate-300">
            Upload trusted documents for LLM to reference.
          </p>
          <div className="mt-4 flex items-center justify-center">
            <div className="group px-4 py-2 rounded-full bg-blue-500/10 hover:bg-blue-500/20 cursor-pointer inline-flex items-center space-x-2 text-sm text-blue-400 transition-all">
              <span>click to explore</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 transform group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      }
      backTitle="Document Upload Strategy"
      backContent={
        <div className="space-y-4">
          <div className="bg-slate-800/50 p-4 rounded-lg">
            <p className="font-medium text-blue-300 mb-3">Best Practices:</p>
            <div className="space-y-3 text-slate-300">
              <div className="border-l-2 border-green-500/50 pl-4">
                <ul className="space-y-2">
                  <li>• Upload relevant, authoritative documents</li>
                  <li>• Specify sections or pages to focus on</li>
                  <li>• Request specific citations from the material</li>
                  <li>• Verify the upload was successful</li>
                </ul>
              </div>

              <div className="bg-slate-900/50 rounded-lg p-3 mt-4">
                <p className="font-medium text-green-300">Example Prompt:</p>
                <p className="text-sm text-slate-300 mt-2">
                  "Using only the uploaded annual report, please summarise the company's financial performance for Q4 2023, citing specific page numbers."
                </p>
              </div>
            </div>
          </div>
        </div>
      }
    />
  </div>
</div>

          {/* Practical Examples */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Prevention in Practice</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="space-y-4">
                <div className="bg-red-500/10 rounded-lg p-6 border border-red-500/20">
                  <h5 className="font-semibold text-red-400 mb-4">Risk-Prone Prompt</h5>
                  <p className="text-slate-300">
                    "Explain all the ways AI will impact healthcare in the next decade."
                  </p>
                  <p className="text-sm text-slate-400 mt-4">
                    Too broad, invites speculation and potential fabrication.
                  </p>
                </div>

                <div className="bg-green-500/10 rounded-lg p-6 border border-green-500/20">
                  <h5 className="font-semibold text-green-400 mb-4">Safer Alternative</h5>
                  <p className="text-slate-300">
                    "Using a web search and the documents I have provided above, for each application, indicate:
                    1. Current implementation status
                    2. Verified benefits
                    3. Known limitations
                    Please note any areas where data is limited or inconclusive."
                  </p>
                </div>
              </div>

              <div className="bg-slate-800/50 rounded-xl p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Key Improvements</h5>
                <div className="space-y-4">
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">1. Time-bound scope</p>
                    <p className="text-sm text-slate-400">Limits to verified current examples</p>
                  </div>
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">2. Source requirement</p>
                    <p className="text-sm text-slate-400">Asks for peer-reviewed studies</p>
                  </div>
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">3. Structured response</p>
                    <p className="text-sm text-slate-400">Clear categories for information</p>
                  </div>
                  <div className="bg-slate-900/50 rounded-lg p-4">
                    <p className="text-slate-300">4. Uncertainty acknowledgment</p>
                    <p className="text-sm text-slate-400">Explicitly asks to note limitations</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Advanced Prevention Techniques */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Advanced Prevention Techniques</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Multi-Step Verification</h5>
                <p className="text-slate-300 mb-4">
                  Use a series of prompts to cross-verify information:
                </p>
                <ol className="space-y-2 text-slate-300 list-decimal list-inside">
                  <li>Initial information gathering</li>
                  <li>Fact verification prompt</li>
                  <li>Source request</li>
                  <li>Contradiction check</li>
                </ol>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Chain of Thought Verification</h5>
                <p className="text-slate-300 mb-4">
                  Request explicit reasoning:
                </p>
                <ul className="space-y-2 text-slate-300">
                  <li>• "Explain your reasoning step by step"</li>
                  <li>• "What evidence supports each point?"</li>
                  <li>• "Identify assumptions made"</li>
                  <li>• "Rate confidence for each step"</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Quick Reference */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
            <h4 className="text-lg font-semibold text-blue-400 mb-4">Prevention Checklist</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-slate-800/50 rounded-lg p-4">
                <h5 className="font-semibold text-blue-300 mb-2">Prompt Design</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>□ Clearly defined scope</li>
                  <li>□ Reference known material (uploads)</li>
                  <li>□ Source requirements</li>
                  <li>□ Confidence indicators</li>
                </ul>
              </div>
              <div className="bg-slate-800/50 rounded-lg p-4">
                <h5 className="font-semibold text-blue-300 mb-2">Response Validation</h5>
                <ul className="space-y-2 text-slate-300">
                  <li>□ Fact verification steps</li>
                  <li>□ Contradiction checks</li>
                  <li>□ Uncertainty acknowledgment</li>
                  <li>□ Clear reasoning paths</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    },

    summary: {
      title: 'Summary & Best Practices',
      content: (
        <div className="space-y-8">
          {/* Overview Banner */}
          <div className="bg-gradient-to-r from-slate-900/40 to-blue-900/20 rounded-xl p-8 border border-slate-700/30">
            <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-6">
              Mastering LLM Prompting
            </h3>
            <p className="text-slate-300 text-lg">
              From basic structure to advanced techniques, effective prompting is your key to getting the most out of AI language models. Let's review the essential concepts and practices we've covered.
            </p>
          </div>

          {/* Key Concepts Review */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Core Concepts Review</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* CO-STAR Framework */}
              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="bg-blue-500/10 p-2 rounded-lg">
                    <span className="text-blue-400 font-bold text-lg">CO-STAR</span>
                  </div>
                  <h5 className="font-semibold text-blue-300">Framework</h5>
                </div>
                <div className="space-y-3">
                  <p className="text-slate-300">
                    <span className="text-blue-400">C</span>ontext: Set the background
                  </p>
                  <p className="text-slate-300">
                    <span className="text-blue-400">O</span>bjective: Define the goal
                  </p>
                  <p className="text-slate-300">
                    <span className="text-blue-400">S</span>tyle: Specify writing style
                  </p>
                  <p className="text-slate-300">
                    <span className="text-blue-400">T</span>one: Set the mood
                  </p>
                  <p className="text-slate-300">
                    <span className="text-blue-400">A</span>udience: Define who it's for
                  </p>
                  <p className="text-slate-300">
                    <span className="text-blue-400">R</span>esponse format: Structure output
                  </p>
                </div>
              </div>

              {/* Advanced Techniques */}
              <div className="bg-slate-800/50 rounded-lg p-6">
                <div className="flex items-center space-x-3 mb-4">
                  <div className="bg-blue-500/10 p-2 rounded-lg">
                    <Sparkles className="w-6 h-6 text-blue-400" />
                  </div>
                  <h5 className="font-semibold text-blue-300">Advanced Strategies</h5>
                </div>
                <ul className="space-y-3 text-slate-300">
                  <li>• Chain of thought prompting</li>
                  <li>• Role assignment</li>
                  <li>• Iterative refinement</li>
                  <li>• Few-shot examples</li>
                  <li>• Verification prompts</li>
                  <li>• Anti-hallucination techniques</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Visual Summary */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Prompting Journey</h4>
            
            <div className="bg-slate-800/50 rounded-xl p-6">
              <img 
                src="https://aidatacert-learning-platform-image-repo-public.s3.us-east-1.amazonaws.com/prompting_jounrey.png"
                alt="Prompting Journey Map"
                className="w-full rounded-lg object-cover mb-4"
              />
              <p className="text-sm text-slate-400 text-center">
                Visual progression from basic to advanced prompting techniques
              </p>
            </div>
          </div>

          {/* Quick Reference Guide */}
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl border border-slate-700/30 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Quick Reference Guide</h4>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* When to Use Basic Prompts */}
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Basic Prompts</h5>
                <p className="text-slate-300 mb-4">Best for:</p>
                <ul className="space-y-2 text-slate-300">
                  <li>• Simple queries</li>
                  <li>• Quick information</li>
                  <li>• General questions</li>
                  <li>• Straightforward tasks</li>
                </ul>
              </div>

              {/* When to Use CO-STAR */}
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">CO-STAR Framework</h5>
                <p className="text-slate-300 mb-4">Best for:</p>
                <ul className="space-y-2 text-slate-300">
                  <li>• Content creation</li>
                  <li>• Detailed explanations</li>
                  <li>• Professional outputs</li>
                  <li>• Consistent formatting</li>
                </ul>
              </div>

              {/* When to Use Advanced */}
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Advanced Techniques</h5>
                <p className="text-slate-300 mb-4">Best for:</p>
                <ul className="space-y-2 text-slate-300">
                  <li>• Complex problems</li>
                  <li>• Critical information</li>
                  <li>• Technical content</li>
                  <li>• Multi-step tasks</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Best Practices */}
          <div className="bg-blue-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-8">
            <h4 className="text-xl font-semibold text-blue-400 mb-6">Essential Best Practices</h4>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">General Guidelines</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>1. Start simple, add complexity as needed</li>
                  <li>2. Be specific about what you want</li>
                  <li>3. Provide context when necessary</li>
                  <li>4. Use examples for clarity</li>
                  <li>5. Review and refine responses</li>
                </ul>
              </div>

              <div className="bg-slate-800/50 rounded-lg p-6">
                <h5 className="font-semibold text-blue-300 mb-4">Quality Checks</h5>
                <ul className="space-y-3 text-slate-300">
                  <li>1. Verify important information</li>
                  <li>2. Check for hallucinations</li>
                  <li>3. Ensure response matches request</li>
                  <li>4. Consider audience needs</li>
                  <li>5. Validate technical details</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Next Steps */}
          <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 backdrop-blur-xl rounded-xl border border-blue-500/20 p-6">
            <div className="flex items-center space-x-3 mb-4">
              <div className="bg-blue-500/10 p-2 rounded-lg">
                <ArrowRight className="w-6 h-6 text-blue-400" />
              </div>
              <h5 className="font-semibold text-blue-400">Moving Forward</h5>
            </div>
            <p className="text-slate-300 mb-4">
              To continue developing your prompting skills:
            </p>
            <ul className="space-y-2 text-slate-300">
              <li>• Practice with different types of requests</li>
              <li>• Experiment with advanced techniques</li>
              <li>• Keep track of successful prompts</li>
              <li>• Stay updated on new developments</li>
              <li>• Share and learn from others' experiences</li>
            </ul>
          </div>
        </div>
      )
    },
    quiz: {
      title: 'Knowledge Check',
      content: (
        <div className="space-y-6">
          <p className="text-gray-300 mb-6">
            Let's test your understanding of LLM prompting techniques. Choose the best answer for each question.
          </p>
          
          <Quiz questions={[
            {
              questionText: "What is the primary benefit of using the CO-STAR framework when crafting prompts?",
              answerOptions: [
                "It makes the prompts longer",
                "It provides a structured approach for clear communication with AI",
                "It reduces the processing time of the LLM",
                "It allows direct access to the model's training data"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "The CO-STAR framework provides a systematic, structured approach to crafting prompts, ensuring clear communication with AI by addressing all crucial elements of effective prompting.",
                incorrect: {
                  0: "Prompt length isn't the goal of CO-STAR; effectiveness comes from structure, not length.",
                  2: "Processing time isn't affected by the framework; it's about improving prompt quality.",
                  3: "No prompting framework can access the model's training data directly."
                }
              }
            },
            {
              questionText: "In the CO-STAR framework, what does the 'S' represent?",
              answerOptions: [
                "Speed of response",
                "Style of writing",
                "Source material",
                "System requirements"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "Style of writing is the 'S' in CO-STAR, focusing on how the response should be written or presented to best serve its purpose.",
                incorrect: {
                  0: "Speed isn't a component of CO-STAR as it's about quality, not response time.",
                  2: "While sources may be important, 'S' specifically refers to style in CO-STAR.",
                  3: "System requirements aren't part of the CO-STAR framework."
                }
              }
            },
            {
              questionText: "Which advanced prompting technique is most appropriate when trying to understand a complex problem?",
              answerOptions: [
                "Role assignment",
                "Chain of thought prompting",
                "Few-shot examples",
                "Iterative refinement"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "Chain of thought prompting is ideal for complex problems as it encourages the AI to break down its reasoning process, making the solution pathway clear and logical.",
                incorrect: {
                  0: "Role assignment helps with perspective but doesn't specifically aid problem breakdown.",
                  2: "Few-shot examples help with format but aren't primarily for problem understanding.",
                  3: "Iterative refinement improves answers but isn't specifically for understanding complexity."
                }
              }
            },
            {
              questionText: "When using role assignment in prompting, what is the main purpose?",
              answerOptions: [
                "To make the response longer",
                "To access different AI models",
                "To provide a specific expert perspective for responses",
                "To bypass the model's safety features"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Role assignment helps the AI adopt a specific expert perspective, providing responses that draw upon the knowledge and approach typical of that expertise.",
                incorrect: {
                  0: "Response length isn't the goal of role assignment.",
                  1: "Role assignment doesn't change or access different models.",
                  3: "Using roles to bypass safety features would be misuse of the technique."
                }
              }
            },
            {
              questionText: "Which approach is most effective for minimising hallucinations when seeking factual information?",
              answerOptions: [
                "Making the prompt longer",
                "Using web search functionality and requiring source citations",
                "Adding more context",
                "Using informal language"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "Requiring source citations and using web search functionality helps verify information and reduces hallucinations by grounding responses in verifiable sources.",
                incorrect: {
                  0: "Longer prompts don't necessarily improve factual accuracy.",
                  2: "More context alone doesn't prevent hallucinations without verification.",
                  3: "Language formality doesn't affect factual accuracy."
                }
              }
            },
            {
              questionText: "What is the best practice when using iterative refinement?",
              answerOptions: [
                "Change the entire prompt with each iteration",
                "Start with complex requirements",
                "Begin with a simple prompt and gradually add complexity",
                "Use technical language from the start"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Starting simple and gradually adding complexity allows for better control over the refinement process and helps identify where adjustments are needed most effectively.",
                incorrect: {
                  0: "Changing everything each time makes it difficult to track improvements.",
                  1: "Starting with complexity can overwhelm the process and make refinement harder.",
                  3: "Technical language isn't necessarily helpful for effective refinement."
                }
              }
            },
            {
              questionText: "Which element of the CO-STAR framework helps ensure the response is appropriate for its intended readers?",
              answerOptions: [
                "Context",
                "Objective",
                "Audience",
                "Response format"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "The Audience element specifically focuses on who will read the response, ensuring the content and style match their needs and understanding level.",
                incorrect: {
                  0: "Context sets the background but doesn't specifically target reader appropriateness.",
                  1: "Objective defines goals but doesn't focus on reader characteristics.",
                  3: "Response format affects presentation but not reader appropriateness directly."
                }
              }
            },
            {
              questionText: "When working with sensitive or critical information, which prompting strategy is most important?",
              answerOptions: [
                "Using casual language",
                "Requesting confidence levels and uncertainty acknowledgment",
                "Making the prompt as short as possible",
                "Using technical jargon"
              ],
              correctAnswer: 1,
              explanations: {
                correct: "Requesting confidence levels and uncertainty acknowledgment is crucial for sensitive information as it helps identify potential risks and limitations in the response.",
                incorrect: {
                  0: "Casual language isn't appropriate for sensitive information handling.",
                  2: "Brief prompts might miss crucial context for sensitive information.",
                  3: "Technical jargon doesn't improve accuracy or safety with sensitive data."
                }
              }
            },
            {
              questionText: "What is the primary purpose of few-shot examples in prompting?",
              answerOptions: [
                "To make the response longer",
                "To test the model's capabilities",
                "To guide the model's response format through examples",
                "To speed up processing time"
              ],
              correctAnswer: 2,
              explanations: {
                correct: "Few-shot examples demonstrate the desired response format and style, helping the model understand exactly how it should structure its output.",
                incorrect: {
                  0: "Response length isn't the goal of few-shot examples.",
                  1: "Testing capabilities isn't the primary purpose of few-shot examples.",
                  3: "Processing time isn't affected by few-shot examples."
                }
              }
            },
            {
              questionText: "Which practice is most important when crafting prompts for technical documentation?",
              answerOptions: [
                "Using informal language",
                "Avoiding specific requirements",
                "Being as vague as possible",
                "Specifying clear format requirements and verification steps"
              ],
              correctAnswer: 3,
              explanations: {
                correct: "Clear format requirements and verification steps are crucial for technical documentation to ensure accuracy, consistency, and usefulness of the generated content.",
                incorrect: {
                  0: "Informal language is inappropriate for technical documentation.",
                  1: "Specific requirements are essential for technical accuracy.",
                  2: "Vagueness leads to inconsistent or incorrect technical documentation."
                }
              }
            }
          ]}
          
          onQuizComplete={() => {
            console.log("Quiz completion callback triggered");
            setIsQuizComplete(true);
          }}


          
          />
        </div>
      )
    }
  }

return (
  <div className="min-h-screen bg-gradient-to-br from-slate-950 via-blue-950 to-slate-950 text-gray-200">
    <div className="lg:hidden fixed top-4 right-4 z-50">
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="p-2 bg-slate-800 rounded-xl border border-slate-700 hover:bg-slate-700 transition-colors"
      >
        <Menu size={24} />
      </button>
    </div>

    <div className={`fixed top-0 left-0 h-full bg-slate-900/95 backdrop-blur-md border-r border-slate-800/50 w-64 transform transition-transform duration-200 ease-in-out z-40 overflow-y-auto
      ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
    >
      <div className="p-6 space-y-6">
        <div className="flex justify-center mb-6">
          <img 
            src="/logo.png" 
            alt="AI DATA CERT Logo" 
            className="h-24 w-auto"
          />
        </div>

        <Link 
          to="/true_productivity_with_llms"
          className="inline-flex items-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors group"
        >
          <ArrowLeft size={20} className="group-hover:-translate-x-1 transition-transform" />
          <span>Back to Modules</span>
        </Link>

        <nav className="space-y-1">
          {sections.map((section) => (
            <button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`w-full text-left px-4 py-3 rounded-xl transition-all flex items-center space-x-3
                ${activeSection === section.id 
                  ? 'bg-blue-500/20 text-blue-400 border border-blue-500/50 shadow-lg shadow-blue-500/20' 
                  : 'hover:bg-slate-800/50 text-slate-300'}`}
            >
              <section.icon size={18} />
              <span>{section.title}</span>
            </button>
          ))}
        </nav>
      </div>
    </div>

    <div className="lg:pl-64">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-slate-900/40 backdrop-blur-xl rounded-xl shadow-xl border border-slate-700/30 p-8 mb-8">
            <div className="border-b border-slate-700 pb-6 mb-6">
              <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-3">
                LLM Prompting
              </h1>
              <h2 className="text-xl text-blue-400">AI DATA CERT</h2>
            </div>

            <div className="space-y-6">
              <h3 className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-blue-400 mb-4">
                {contentSections[activeSection]?.title}
              </h3>
              {contentSections[activeSection]?.content}
            </div>
          </div>

          {/* Next Module Button - Only show when on quiz section and quiz is complete */}
          {activeSection === 'quiz' && isQuizComplete && (
            <div className="mt-8 flex justify-end">
              <Link 
                to="/true_productivity_with_llms_1_6_workshop"
                className="inline-flex items-center space-x-2 px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white rounded-xl transition-colors shadow-lg shadow-blue-500/20 group"
              >
                <span>1.5 Complete - Press to start 1.6</span>
                <ArrowRight size={20} className="group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          )}

        </div>
      </div>
    </div>
  </div>
);
};

export default Onepfive;